import { Box } from 'atomic-layout';
import Image from 'next/legacy/image';
import { Paragraph } from 'components/atoms/Text';
import React from 'react';
import { Summary } from 'components/atoms/Text';
import format from 'date-fns/format';
import styled, { useTheme } from 'styled-components';
import { toCurrencyString } from 'utils/currency';
import { useCalculation } from 'components/content/calculator/calculationContext';
import { SATOSHI_FACTOR } from '../../../../utils/convertBtcSats';

/**
 * Invest {amountToInvest} per {stackingFrequency}
 * To reach your {goalAmount}{goalAmountUnit} by {goalDate}
 *
 * Complete the calculator inputs to find your investment targets.
 */

const SummaryCard = styled(Box)(
  ({
    theme,
    marginBottomOnXl,
    marginBottomSm,
    marginBottomLg,
    marginTopLg,
  }) => ({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    backdropFilter: `blur(${theme.glassEffect.blur})`,
    '-webkit-backdrop-filter': `blur(${theme.glassEffect.blur})`,
    borderRadius: theme.glassEffect.borderRadius,
    background: theme.colors.glassBackground,
    overflow: 'hidden',
    minHeight: '115px',
    maxWidth: '600px',
    boxShadow: theme.colors.shadow,
    [`@media screen and (max-width: ${theme.breakpoints.sm.maxWidth})`]: {
      justifyContent: 'left',
    },
    [`@media screen and (min-width: ${theme.breakpoints.lg.maxWidth})`]: {
      marginBottom: `${marginBottomOnXl} !important`,
    },
    [`@media screen and (min-width: ${theme.breakpoints.md.minWidth})`]: {
      marginBottom: `${marginBottomSm}rem`,
    },
    [`@media screen and (min-width: ${theme.breakpoints.lg.minWidth})`]: {
      marginBottom: `${marginBottomLg}rem`,
      marginTop: `${marginTopLg}rem`,
    },
  })
);

const BitcoinImageBox = styled.div(({ theme }) => ({
  height: '100%',
  background: theme.colors.secondaryGradient,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: '0 1 100px',
  padding: '0 0.2rem',
  [`@media screen and (min-width: ${theme.breakpoints.sm.minWidth})`]: {
    minWidth: '120px',
    padding: 'unset',
  },
}));

const TextWrapperBox = styled.div`
  padding: 0.5rem 1rem;
  text-align: right;
  flex: 1;
`;

const Text = styled.p(({ theme, $big, $bold, $nowrap, $color }) => ({
  color: $color ? theme.colors[$color] : theme.colors.text,
  fontSize: $big ? '24px' : '14px',
  fontWeight: $big || $bold ? 'bold' : 'normal',
  margin: $big ? '0.5rem 0' : 0,
  lineHeight: 1.2,
  padding: 0,
  whiteSpace: $nowrap ? 'no-wrap' : 'pre-line',
}));

const Span = styled.span(({ theme, $color, $nowrap }) => ({
  color: $color ? theme.colors[$color] : theme.colors.text,
  fontSize: 'inherit',
  whiteSpace: $nowrap ? 'no-wrap' : 'preline',
}));

const templateString = (template, formattedValues) => {
  return Object.entries(formattedValues).reduce((str, [key, value]) => {
    return str.replace(new RegExp(`\{${key}\}`, 'g'), value || '');
  }, template);
};

const SummaryText = ({ byGoalSummaryText, byInvestmentAmountSummaryText }) => {
  const {
    useStackingAmount,
    stackingAmount,
    singleStatData,
    amountToInvest,
    stackingFrequency,
    goalDate,
    currency,
    isSatoshis,
  } = useCalculation();
  const marginBottomSm = singleStatData?.purchasingPower ? 0 : 0;
  const marginBottomLg = singleStatData?.purchasingPower ? 0.8 : 1.2;

  const { totalCrypto } = singleStatData || {};
  const totalCryptoToUse = isSatoshis
    ? totalCrypto?.mul(SATOSHI_FACTOR)
    : totalCrypto;
  const totalAmountToInvestInUnit = amountToInvest;

  const summaryTextProps = useStackingAmount
    ? byInvestmentAmountSummaryText
    : byGoalSummaryText;
  const { upper, stat, unit, lower, fallbackText } = summaryTextProps || {};

  const templateValues = React.useMemo(() => {
    return {
      amountToInvest:
        amountToInvest &&
        currency &&
        toCurrencyString(
          useStackingAmount ? stackingAmount : totalAmountToInvestInUnit,
          currency
        ),
      stackingFrequency: stackingFrequency
        ?.replace(/i?ly$/i, '')
        .replace('DA', 'DAY')
        .toLowerCase(),
      totalAmount: totalCryptoToUse?.toNumber()?.toLocaleString(undefined, {
        maximumFractionDigits: isSatoshis ? 0 : totalCrypto >= 1 ? 3 : 8,
      }),
      totalAmountUnit: isSatoshis ? 'sats' : 'BTC',
      totalAmountUnitGoal: isSatoshis ? 'SatsGoal' : 'BTC Goal',
      goalDate: goalDate && format(goalDate, 'do MMMM yyyy'),
    };
  }, [
    amountToInvest,
    currency,
    useStackingAmount,
    stackingAmount,
    stackingFrequency,
    totalCrypto,
    isSatoshis,
    goalDate,
  ]);

  const color = 'primary';
  const theme = useTheme();
  const { isDark } = theme;

  return (
    <SummaryCard
      maxHeight={'100%'}
      marginBottomOnXl={'0px'}
      marginBottomSm={marginBottomSm}
      marginBottomLg={marginBottomLg}
    >
      <BitcoinImageBox $color={color}>
        <Image
          src={require('images/bitcoinLogo2.svg')}
          style={{
            transform: 'rotate(1deg)',
            filter: !isDark && 'brightness(0) invert(1)',
          }}
          alt="Bitcoin Logo"
        />
      </BitcoinImageBox>
      <TextWrapperBox>
        {!!amountToInvest && (
          <>
            <Text $color={color} $bold $nowrap>
              {templateString(upper, templateValues)}
            </Text>
            <Text $big $nowrap>
              <Span $color={color}>{templateString(stat, templateValues)}</Span>{' '}
              <Span $nowrap>{templateString(unit, templateValues)}</Span>
            </Text>
            <Text>{templateString(lower, templateValues)}</Text>
          </>
        )}
        {!amountToInvest && (
          <Summary $padding="initial" $textAlign="right">
            <Paragraph $padding="initial" $textAlign="right">
              {fallbackText}
            </Paragraph>
          </Summary>
        )}
      </TextWrapperBox>
    </SummaryCard>
  );
};
export default SummaryText;
